import bulkActionTemplate from "./ad-unit-bulk-action.html";
import _ from "lodash";
import {ADN_TARGETING_TYPES} from "../targeting/targeting-constants";

export const adUnitBulkAction = function(ctrl, $resources, resources, localResources) {

  const $uibModal = $resources.$uibModal;
  const LocalNetworkProfile = localResources.LocalNetworkProfile;
  const TimeZones = resources.TimeZones;

  return function() {
    const modalInstance = $uibModal.open({
      template: bulkActionTemplate,
      controllerAs: 'modalCtrl',
      size: 'lg',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this,
          commons = adUnitCommons(modalCtrl, TimeZones, LocalNetworkProfile, 'item');

        modalCtrl.item = {};
        modalCtrl.form = {};
        modalCtrl.editing = {};
        modalCtrl.item.name = "{currentName}";
        modalCtrl.item.description = "{currentDescription}";
        modalCtrl.item.labelType = 'REPLACE';
        modalCtrl.item.matchingLabelType = 'REPLACE';
        modalCtrl.item.adUnitOption = 'RANGE';

        modalCtrl.item.dimType = 'REPLACE';
        modalCtrl.item.dimensions = _.isArray(modalCtrl.item.dimensions) ? modalCtrl.item.dimensions : [[]];
        modalCtrl.item.dimensions[0] = _.isArray(modalCtrl.item.dimensions[0]) ? modalCtrl.item.dimensions[0] : [];

        modalCtrl.removeDimensions = function(index) {
          _.pullAt(modalCtrl.item.dimensions, index);
          modalCtrl.eventHook.removeControlHook('dims' + index + '.width');
          modalCtrl.eventHook.removeControlHook('dims' + index + '.height');
        };
        modalCtrl.addDimensions = function() {
          modalCtrl.item.dimensions = modalCtrl.item.dimensions || [];
          modalCtrl.item.dimensions.push([]);
        };
        modalCtrl.eventHook = {};

        modalCtrl.count = _.keys(ctrl.bulkSelection).length;

        commons.setTimezones();
        commons.setTimezoneChange();
        commons.setTargetingTypes();

        modalCtrl.copy = function() {

          const adUnits = _.map(ctrl.bulkSelection, function(v, k) {
            const obj = {id: k};

            if (modalCtrl.editing.name) {
              obj.name = modalCtrl.item.name.replace("{currentName}", v.name);
            }
            if (modalCtrl.editing.description) {
              obj.description = modalCtrl.item.description.replace("{currentDescription}", v.description || "") || null;
            }
            if (modalCtrl.item.adUnitOption === 'FIXED') {
              if (modalCtrl.editing.dimensions) {
                const theDims = _.filter(modalCtrl.item.dimensions, function(dim) {
                  return _.isFinite(dim[0]) && dim[0] > 0 && _.isFinite(dim[1]) && dim[1] > 0;
                });
                obj.dimensions = modalCtrl.item.dimType === 'APPEND' ? v.dimensions.concat(theDims || []) : theDims || [];
                obj.adUnitOption = 'FIXED';
                resetDimensionsBasedOnAdUnitOption(obj);
              }
            }
            if (modalCtrl.item.adUnitOption === 'RANGE') {
              if (modalCtrl.editing.minWidth) {
                obj.minWidth = modalCtrl.item.minWidth;
                obj.minHeight = modalCtrl.item.minHeight;
                obj.adUnitOption = 'RANGE';
                resetDimensionsBasedOnAdUnitOption(obj);
              }
              if (modalCtrl.editing.maxWidth) {
                obj.width = modalCtrl.item.width;
                obj.height = modalCtrl.item.height;
                obj.adUnitOption = 'RANGE';
                resetDimensionsBasedOnAdUnitOption(obj);
              }
            }
            if (modalCtrl.editing.pageSize) {
              obj.pageSize = modalCtrl.item.pageSize || null;
            }
            if (modalCtrl.editing.floorPrice) {
              obj.floorPrice = modalCtrl.item.floorPrice || null;
            }
            if (modalCtrl.editing.site) {
              obj.site = modalCtrl.item.site || null;
            }
            if (modalCtrl.editing.targetingControl && modalCtrl.item.targetingControl) {
              obj.targetingControl = modalCtrl.item.targetingControl || null;
              obj.allowedTargetTypes = modalCtrl.item.allowedTargetTypes || [];
            }
            if (modalCtrl.editing.matchingLabels) {
              obj.matchingLabels = modalCtrl.item.matchingLabelType === 'APPEND' ? v.matchingLabels.concat(modalCtrl.item.matchingLabels || []) : modalCtrl.item.matchingLabels || [];
            }
            if (modalCtrl.editing.labels) {
              obj.labels = modalCtrl.item.labelType === 'APPEND' ? v.labels.concat(modalCtrl.item.labels || []) : modalCtrl.item.labels || [];
            }
            if (modalCtrl.editing.timeZone) {
              obj.timeZone = commons.getBeforeSubmitTimeZoneValue();
            }
            if (modalCtrl.editing.urlAsCategory) {
              obj.urlAsCategory = modalCtrl.item.urlAsCategory;
              obj.qsAsKeyValues = modalCtrl.item.qsAsKeyValues;
            }

            return obj;
          });

          ctrl.miscBulkAction(modalInstance, modalCtrl, adUnits);
        };
      }
    });
  };
};

export const resetDimensionsBasedOnAdUnitOption = function(model) {
  if (model.adUnitOption === 'RANGE') {
    model.dimensions = null;
  } else {
    model.minWidth = null;
    model.minHeight = null;
    model.height = null;
    model.width = null;
  }
};

export const adUnitCommons = function(ctrl, timezonesResource, LocalNetworkProfile, pParam) {
  const param = pParam || 'model';
  return {
    setTimezones: function() {
      if (!ctrl.timeZones) {
        timezonesResource.get().then(function(timeZones) {
          ctrl.timeZones = timeZones;
          ctrl.specifyTimeZone = !!ctrl[param].timeZone;
        });
      }
    },
    setTimezoneChange: function() {
      ctrl.timeZoneChange = function() {
        if (ctrl.specifyTimeZone) {
          ctrl[param].timeZone = ctrl[param].timeZone || LocalNetworkProfile.get('timeZone');
        } else {
          ctrl[param].timeZone = null;
        }
      };
    },
    setTargetingTypes: function() {
      ctrl.targetingTypes = _.map(ADN_TARGETING_TYPES, function(val) {
        return val;
      });
    },
    getBeforeSubmitTimeZoneValue: function() {
      return ctrl.specifyTimeZone ? ctrl[param].timeZone : null;
    }
  };
};