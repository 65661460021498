import * as _ from "lodash";

export const adTagLinker = function(ctrl, $resources) {
  const $state = $resources.$state;

  return function() {
    const tags = _.map(ctrl.bulkSelection, function(tag) {
      return {
        auId: tag.tagId,
        auW: tag.width,
        auH: tag.height,
        auName: tag.name
      };
    });
    $state.go('app.ad-tag', {design: JSON.stringify(tags)});
  };
};
